<template>
	<div class="pageContainer">
		<web-header></web-header>
		<div class="content">
			<router-title :menuList="menuList[0]" :list="menuList[1]" />
			<div v-if="list.length>0" class="list animate__animated animate__fadeInLeft">
				<!-- <div v-for="(item,idx) in list" :key="idx" class="listItem">
					<div><img :src="item.surfacePlot" alt="" class="img"></div>
					<p class="title">{{item.name}}</p>
					<div class="time">{{item.beginTime}}</div>
					<div class="yuyue">
						<p v-if="item.status == 0"><span
								style="color:#E51E0B">{{item.bookedPeople ? item.bookedPeople:0}}人</span><span>预约</span>
						</p>
						<p v-if="item.status == 1"><span
								style="color:#E51E0B">{{item.viewPeople ? item.viewPeople:0}}人</span><span>在线</span>
						</p>
						<p v-if="item.status == 2"></p>
						<div>
							<el-button class="bnt2" v-if="item.status == 1 && item.stuToLive" icon="el-icon-time" @click="watchInfo(item)">立即进入</el-button>
							<el-button class="bnt3" v-if="item.status == 2" icon="el-icon-time">已结束</el-button>
							<el-button class="bnt3" v-if="item.status == 0 && item.stuToLive" icon="el-icon-time">已预约</el-button>
							<el-button class="bnt1" v-if="item.status != 2 && !item.stuToLive" icon="el-icon-time" @click="toYuyue(item)">立即预约</el-button>
						</div>
					</div>
				</div> -->
				<div v-for="(item,index) in list" :key="index" class="item">
					<div class="item_l">
						<img :src="item.surfacePlot" alt="" class="img">
					</div>
					<div class="item_r">
						<div class="info">
							<span class="live_name">{{item.name}}</span>
							<img src="../../assets/live/icon_category.png" alt="">
							<div class="live_class">{{item.thirdCourseClassifyName}}</div>
						</div>
						<div class="lecturer">主讲教师：{{item.teacherName}}</div>
						<div class="live_time">直播时间：{{item.beginTime}}</div>
						<div class="training_introduction">
							<h3>培训介绍</h3>
							<div class="introduce">{{item.introduce}}</div>
						</div>
						<div class="btn_row">
							<!-- 正在直播 -->
							<div class="btn living_btn" v-if="item.status == 1 && item.stuToLive"
								@click="watchInfo(item.id)">
								<img src="../../assets/live/liveNow.png" alt="">
								<span>正在直播</span>
							</div>
							<!-- 已预约 -->
							<div class="btn" v-if="item.status == 0 && item.stuToLive">
								<img src="../../assets/live/icon_booked.png" alt="">
								<span>已预约</span>
							</div>
							<!-- 已结束 -->
							<div class="btn" v-if="item.status === 2 && item.stuToLive">

								<img src="../../assets/live/icon_finished.png" alt="">
								<span>已结束</span>
							</div>
							<!-- 立即预约 -->
							<div class="btn reserve_btn" v-if="!item.stuToLive" @click="toYuyue(item.id)">
								<img src="../../assets/live/icon_no_booked.png" alt="">
								<span>立即预约</span>
							</div>
							<span class="people_nums" v-if="item.cost">预约金额：￥{{item.cost}}</span>
							<span class="people_nums" v-else>免费预约</span>
							<span class="people_num" v-if="item.status == 1">在线人数：{{item.viewPeople}}人</span>
							<span class="people_num" v-else>预约人数：{{item.bookedPeople}}人</span>
						</div>
					</div>
				</div>
				<div class="pagination">
					<el-pagination background layout="prev, pager, next" :page-size="pageSize"
						@current-change="handleCurrentChange" :page-count="count">
					</el-pagination>
				</div>
			</div>
			<!-- <div class="noTeacter" v-else-if="list.length === 0">
				<div class="noTeacter_img">
					<img src="../../assets/imgs/4.png" alt="">
					<div>抱歉，暂时没有您想要的内容</div>
				</div>
			</div> -->
		</div>
		<web-footer></web-footer>
	</div>
</template>

<script>
	import WebFooter from '../../components/webFooter.vue'
	import webHeader from '../../components/webHeader.vue'
	import RouterTitle from "../../components/routerTitle.vue";
	import {
		mapState
	} from 'vuex';
	export default {
		components: {
			webHeader,
			WebFooter,
			RouterTitle
		},
		data() {
			return {
				menuList: ["首页", "直播课堂"],
				list: [

				],
				pageSize: 4,
				pageNumber: 1,
				count: 0
			}
		},
		computed: {
			...mapState(['isLogin'])
		},
		created() {
			this.getByPage()
		},
		methods: {
			//去直播详情
			watchInfo(id) {
				if (!this.isLogin) return this.$message.error('请先登录')
				this.$router.push({
					path: '/liveInfo',
					query: {
						id
					}
				})
			},
			getByPage() {
				let data = {
					pageSize: this.pageSize,
					pageNumber: this.pageNumber
				}
				this.$http.post('liveGetByPage', data).then((res) => {
					if (res.code == 200) {
						this.list = res.data.records
						this.count = res.data.pages
					} else {
						this.$message.error(res.message);
					}
				})
			},
			toYuyue(id) {
				if (!this.isLogin) return this.$message.error('请先登录')
				this.$router.push({
					path: '/livedetails',
					query: {
						id
					}
				})
			},
			handleCurrentChange(e) {
				this.pageNumber = e
				this.getByPage()
			}
		}
	}
</script>

<style lang="less" scoped>
	.content {
		width: 1200px;
		background: #F9F9F9;
		margin: auto;

		.list {
			.item {
				display: flex;
				margin-bottom: 30px;

				.item_l {
					width: 398px;
					height: 277px;

					.img {
						width: 100%;
						height: 100%;
					}
				}

				.item_r {
					margin-left: 28px;
					flex: 2;

					.info {
						display: flex;
						align-items: center;
						margin-bottom: 18px;

						.live_name {
							font-size: 20px;
							font-weight: bold;
							color: #333333;
							margin-right: 16px;
						}

						.live_class {
							background: rgba(164, 208, 255, .5);
							border-radius: 2px;
							font-size: 14px;
							color: #59AAFF;
							padding: 3px 4px;
							margin-left: 9px;
						}
					}

					.lecturer,
					.live_time {
						font-size: 16px;
						color: #666666;
						margin-bottom: 18px;
					}

					.training_introduction {
						margin-bottom: 18px;

						h3 {
							font-size: 16px;
							font-weight: bold;
							color: #E51E0B;
							margin-bottom: 19px;
						}

						.introduce {
							font-size: 16px;
							color: #333333;
							line-height: 24px;
							text-indent: 2em;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 3;
							overflow: hidden;
						}
					}

					.btn_row {
						display: flex;
						align-items: flex-end;

						.btn {
							display: flex;
							justify-content: center;
							align-items: center;
							width: 151px;
							height: 53px;
							line-height: 53px;
							background: #EEEEEE;
							border-radius: 5px;
							font-size: 18px;
							font-weight: 500;
							color: #999999;
							cursor: pointer;

							span {
								margin-left: 15px;
							}

							img {
								width: 24px;
							}
						}

						.living_btn {
							background: #FBAF25;
							color: #ffffff;
						}

						.reserve_btn {
							background: #E51E0B;
							color: #ffffff;
						}

						.people_num {
							font-size: 16px;
							color: #08AD2D;
							margin-left: 20px;
						}
						.people_nums {
							font-size: 16px;
							color: #E51E0B;
							margin-left: 20px;
						}
					}
				}
			}
		}

		// .list {
		// 	display: flex;
		// 	flex-wrap: wrap;

		// 	.listItem {
		// 		width: 356px;
		// 		margin-right: 10px;
		// 		box-shadow: 0px 0px 13px 0px rgba(53, 53, 53, 0.13);
		// 		margin-bottom: 45px;

		// 		.img {
		// 			width: 356px;
		// 			height: 181px;
		// 			cursor: pointer;
		// 		}

		// 		.title {
		// 			font-size: 18px;
		// 			padding: 0 10px;
		// 			margin-bottom: 19px;
		// 			font-weight: 500;
		// 			color: #333333;
		// 			overflow: hidden;
		// 			text-overflow: ellipsis;
		// 			white-space: nowrap;
		// 		}

		// 		.time {
		// 			font-size: 18px;
		// 			padding: 0 10px;
		// 			margin-bottom: 38px;
		// 			font-weight: 400;
		// 			color: #999999;
		// 		}

		// 		.yuyue {
		// 			padding: 0 10px;
		// 			display: flex;
		// 			justify-content: space-between;
		// 			margin-bottom: 20px;

		// 			.bnt1 {
		// 				background: #E51E0B;
		// 				color: #F9F9F9;
		// 			}

		// 			.bnt2 {
		// 				background: #FBAF25;
		// 				color: #F9F9F9;
		// 			}

		// 			.bnt3 {
		// 				background: #EEEEEE;
		// 				color: #999999;
		// 			}
		// 		}
		// 	}
		// }

		.pagination {
			width: 100%;
			margin-bottom: 85px;
			text-align: center;

			/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
				background: #E51E0B;
			}
		}
	}

	.noTeacter {
		// margin-left: 45%;
		color: #666;
		height: 60vh;
		text-align: center;
		margin-top: 200px;
		font-size: 14px;
		color: #ccc;

		.noTeacter_img {
			img {
				width: 188px;
				height: 141px;
				margin-bottom: 10px;
			}
		}
	}
</style>
